<template>
    <Accordion :multiple="true" :activeIndex="0">
        <AccordionTab header="Associadas">
            <regras-associadas-component
                :regras="regrasAssociadas"
                :regraAtualizacao="regraAtualizacao"
                @selecionar="regrasAssociadasSelecionadas = $event"
                @desassociar="desassociar()"
            ></regras-associadas-component>
        </AccordionTab>
        <AccordionTab header="Disponíveis">
            <regras-disponiveis-component
                :regras="regrasDisponiveis"
                :regraAtualizacao="regraAtualizacao"
                @selecionar="regrasDisponiveisSelecionadas = $event"
                @associar="associar()"
            ></regras-disponiveis-component>
        </AccordionTab>
    </Accordion>
</template>

<script>
import RegrasAssociadasComponent from './RegrasAssociadas';
import RegrasDisponiveisComponent from './RegrasDisponiveis';

export default {
    props: {
        regrasAssociadas: {
            type: Array,
            default: () => [],
        },
        regrasDisponiveis: {
            type: Array,
            default: () => [],
        },
        regraAtualizacao: {
            type: String,
            default: () => '',
        },
    },

    components: {
        RegrasAssociadasComponent,
        RegrasDisponiveisComponent,
    },

    emits: ['associar', 'desassociar'],

    data() {
        return {
            regrasAssociadasSelecionadas: [],
            regrasDisponiveisSelecionadas: [],
        };
    },

    methods: {
        desassociar() {
            this.$emit('desassociar', this.regrasAssociadasSelecionadas);
        },

        associar() {
            this.$emit('associar', this.regrasDisponiveisSelecionadas);
        },
    },
};
</script>
