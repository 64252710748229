<template>
    <painel titulo="Perfil de Acesso" icone="pi pi-sliders-h" :refreshFunction="atualizar">
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-4">
                <label>Código</label>
                <InputText type="text" v-model="codigo" :disabled="desabilitaForm || desabilitaCodigo" @input="v$.codigo.$touch()" />
                <small class="p-error" v-if="v$.codigo.$error">O código é obrigatório</small>
            </div>
            <div :class="`field col-12 md:col-${$temAcessoView('CTRLACS-PA-05') ? '6' : '8'}`">
                <label>Descrição</label>
                <InputText type="text" v-model="descricao" :disabled="desabilitaForm" @input="v$.descricao.$touch()" />
                <small class="p-error" v-if="v$.descricao.$error">A descrição é obrigatória</small>
            </div>
            <div class="field col-12 md:col-2" v-if="$temAcessoView('CTRLACS-PA-05')">
                <label>Nível</label>
                <InputText type="text" v-model="nivel" :disabled="desabilitaForm" />
            </div>
        </div>
        <slot name="botoes"></slot>
        <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar" v-if="!desabilitaForm" />
        <Button
            label="Salvar"
            icon="pi pi-check-circle"
            class="ml-2 p-button-primary"
            @click="confirmarSalvar()"
            v-if="!desabilitaForm"
            :disabled="v$.$invalid"
        />
    </painel>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        acao: {
            type: String,
        },

        perfil: {
            type: Object,
        },

        erros: {
            type: Array,
        },

        cancelar: {
            type: Function,
        },
    },

    emits: ['salvar', 'atualizar'],

    data() {
        return {
            codigo: null,
            descricao: null,
            nivel: 0,
        };
    },

    validations() {
        return {
            codigo: { required },
            descricao: { required },
        };
    },

    methods: {
        preencher() {
            this.codigo = this.perfil ? this.perfil.codPerfilAcesso : this.perfil;
            this.descricao = this.perfil ? this.perfil.descricaoPerfilAcesso : this.perfil;
            if (this.$temAcessoView('CTRLACS-PA-05')) {
                this.nivel = this.perfil ? this.perfil.nivel : this.perfil;
            }
            this.v$.$touch();
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.acao.toLowerCase()} o perfil de acesso?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            let perfilDto = {
                codigo: this.codigo,
                descricao: this.descricao,
            };
            if (this.$temAcessoView('CTRLACS-PA-05')) {
                perfilDto.nivel = this.nivel;
            }
            this.$emit('salvar', perfilDto);
        },

        atualizar() {
            this.$emit('atualizar');
        },
    },

    computed: {
        desabilitaForm() {
            return this.acao === 'DETALHAR';
        },

        desabilitaCodigo() {
            return this.perfil?.codPerfilAcesso === 'ADMINISTRADOR';
        },
    },

    watch: {
        perfil() {
            this.preencher();
        },
    },
};
</script>
