<template>
    <Button label="Excluir" icon="pi pi-trash" class="ml-2 p-button-danger" @click="confirmarExcluir()" />
</template>

<script>
import PerfisServices from './services';

export default {
    props: {
        perfil: {
            type: Object,
        },
    },

    methods: {
        confirmarExcluir() {
            this.$confirm.require({
                message: `Tem certeza que deseja excluir o perfil de acesso?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.excluir();
                },
            });
        },

        excluir() {
            this.$store.dispatch('addRequest');
            PerfisServices.excluir(this.perfil.perfilAcessoId).then((response) => {
                if (response?.success) {
                    this.$toast.add({ severity: 'success', summary: 'Exclusão de Perfil', detail: 'Perfil de acesso excluído com sucesso', life: 3000 });
                    this.toListagem();
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Exclusão de Perfil', detail: response.errors[0], life: 3000, group: 'dialog' });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toListagem() {
            this.$store.dispatch('setAtualizar', true);
            this.$router.push({
                name: 'Perfis',
            });
        },
    },
};
</script>
