<template>
    <perfil-form acao="DETALHAR" :perfil="perfil" @atualizar="obterPerfil()">
        <template #botoes>
            <Button label="Atualizar" icon="pi pi-pencil" @click="toAtualizar()" />
            <btn-inserir class="ml-2"></btn-inserir>
            <btn-excluir :perfil="perfil" v-if="permiteExcluir"></btn-excluir>
            <Button label="Voltar" icon="pi pi-arrow-circle-left" class="ml-2 p-button-warning" @click="voltar()" />
        </template>
    </perfil-form>
    <regras @atualizar-tem-regras-associadas="temRegrasAssociadas = $event"></regras>
</template>

<script>
import PerfisServices from './services';
import PerfilForm from './PerfilForm';
import BtnInserir from './BtnInserir';
import BtnExcluir from './BtnExcluir';
import Regras from './Regras';

export default {
    components: {
        PerfilForm,
        BtnInserir,
        BtnExcluir,
        Regras,
    },

    data() {
        return {
            perfil: null,
            temRegrasAssociadas: false,
        };
    },

    methods: {
        obterPerfil() {
            this.$store.dispatch('addRequest');
            PerfisServices.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.perfil = response.data;
                } else {
                    this.perfil = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        voltar() {
            this.$router.push({ name: 'Perfis' });
        },

        toAtualizar() {
            this.$router.push({
                name: 'Perfis_Atualizar',
                params: {
                    id: this.$route.params.id,
                },
            });
        },
    },

    computed: {
        permiteExcluir() {
            return this.$temAcessoView('CTRLACS-PA-07') && this.perfil && this.perfil?.codPerfilAcesso !== 'ADMINISTRADOR' && !this.temRegrasAssociadas;
        },
    },

    mounted() {
        this.obterPerfil();
    },
};
</script>
