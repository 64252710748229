import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_ADMINISTRACAO_PORT}${process.env.VUE_APP_API_PATH}/administracao`;

export default {
    obterTodos() {
        return axiosJwt.get(`${api}/controleacesso/perfisacesso`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${api}/controleacesso/perfisacesso/${id}`);
    },

    obterRegras(id) {
        return axiosJwt.get(`${api}/controleacesso/perfisacesso/${id}/regras`);
    },

    inserir(perfil) {
        return axiosJwt.post(`${api}/controleacesso/perfisacesso`, perfil);
    },

    atualizar(perfil) {
        return axiosJwt.put(`${api}/controleacesso/perfisacesso`, perfil);
    },

    excluir(id) {
        return axiosJwt.delete(`${api}/controleacesso/perfisacesso/${id}`);
    },

    associar(regras) {
        return axiosJwt.post(`${api}/controleacesso/perfisacesso/associar`, regras);
    },

    desassociar(regras) {
        return axiosJwt.post(`${api}/controleacesso/perfisacesso/desassociar`, regras);
    },
};
