<template>
    <tabela :data="regras" dataKey="regraAcessoId" :globalFilterFields="['areaDescr', 'subAreaDescr', 'codRegraAcesso', 'descricaoRegraAcesso']" stateKey="dt-regrasdisponiveis">
        <template #botoes>
            <Button label="Associar Selecionadas" class="ml-2" icon="pi pi-link" @click="$emit('associar')" :disabled="regrasSelecionadas.length < 1" v-if="$temAcessoView(regraAtualizacao)" />
        </template>
        <template #conteudo>
            <Column headerStyle="width: 3em">
                <template #body="slotProps">
                    <Checkbox name="perfis" :value="slotProps.data.regraAcessoId" v-model="regrasSelecionadas" />
                </template>
            </Column>
            <Column field="codRegraAcesso" header="Código" :sortable="true">
                <template #body="slotProps">
                    <span>{{ slotProps.data.codRegraAcesso }}</span>
                </template>
            </Column>
            <Column field="descricaoRegraAcesso" header="Descrição" :sortable="true">
                <template #body="slotProps">
                    {{ slotProps.data.descricaoRegraAcesso }}
                </template>
            </Column>
        </template>
    </tabela>
</template>

<script>
export default {
    props: {
        regras: {
            type: Array,
        },
        regraAtualizacao: {
            type: String,
            default: () => '',
        },
    },

    emits: ['selecionar', 'associar'],

    data() {
        return {
            regrasSelecionadas: [],
        };
    },

    watch: {
        regrasSelecionadas() {
            this.$emit('selecionar', this.regrasSelecionadas);
        },

        regras() {
            this.regrasSelecionadas = [];
        },
    },
};
</script>
